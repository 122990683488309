import React, { useContext, useState,useEffect } from 'react'
import { SignModalContext } from '../../context/SignModalContext';
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { images } from "./../Utility/Images";
import { errorToast, successToast } from '../Utility/Toast';
import { sentOtp } from '../../services/User.service';
import { useSelector, useDispatch } from "react-redux";
import { login } from '../../redux/features/auth/authSlice';


const ProductPopUp = () => {

  const { openSignModal, closeSignModal } = useContext(SignModalContext);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  const [otpsent, setotpsent] = useState(false);
  

  const handlepoup = () => {
  		let isPopUp = window.sessionStorage.getItem("aaispoup");
  	if(!isPopUp){
  			setotpsent(true);;
  			window.sessionStorage.setItem("aaispoup","true")
  	} 
  }

  useEffect(()=>{
  	setTimeout(handlepoup,2500)
  },[])

  if (!otpsent) return null;

  return (
    <Modal
    show={otpsent}
    centered
    onHide={() => setotpsent(false)}
    className="rounded-5"
  >
    <Modal.Body className="sign-in-modal custom-modal subscription-card-container rounded-5">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        onClick={() => setotpsent(false)}
      ></button>
      <div>
        <Link to="/" className="navbar-brand pt-3">
          <img src={images.logo} alt="" className="main-logo img-fluid" />
        </Link>
      </div>
      {<h2 className="heading">Add Product </h2>}
      <form className="form row mt-4">
     

        <div className="col-12 mb-0">
         
            {isAuthorized ? (
           <Link
            to="/MyProducts"
            className="btn btn-custom mt-2 text-white yellow-bg py-2 w-100"
          >
            Add Product
          </Link> 
          ) : (
            <button
              type="button"
              onClick={() => {
                setotpsent(false);
                 openSignModal();
              }}
              className="btn btn-custom text-white yellow-bg py-2 w-100"
            >
              Login In
            </button>
          )}

                </div> 
            
     
      </form>
    </Modal.Body>
  </Modal>
  )
}

export default ProductPopUp