import axios from "axios";
import { url } from "./url.service";



export const addNewsLetter = async (obj) => {
		let tempobj = {
		...obj,
		SourceName:'WEB'
	}
    return axios.post(`${url}/newsLetter`, tempobj)
}
