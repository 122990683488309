export const ROLES_CONSTANT = {
  ADMIN: "ADMIN",
  SUBADMIN: "SUBADMIN",
  USER: "USER",
  MANUFACTURER: "MANUFACTURER/IMPORTER",
  DISTRIBUTOR: "DISTRIBUTOR",
  DEALER: "DEALER",
  APPROVERS: "APPROVERS",
  RETAILER:"RETAILER",
  CONTRACTOR:"CONTRACTOR"
}

export const AUTH_TOKEN = "PLYWOOD_BAZAR_AUTH";

export const pageIndex = "page";
export const defaultPageIndex = 0;
export const pageSize = "perPage";
export const defaultPageSize = 10;

export const headerNameKey = "headerName";

