import { axiosApiInstance } from "../App";
import { url } from "./url.service";

let serverUrl = `${url}/userRequirement`;

export const addUserRequirement = async (obj) => {
	let tempobj = {
		...obj,
		SourceName:'WEB'
	}
    return axiosApiInstance.post(`${serverUrl}/addUserRequirement`, tempobj)
}



let serverUrl1 = `${url}/quickenqury`;

export const addquickenquiry = async (obj) => {
		let tempobj = {
		...obj,
		SourceName:'WEB'
	}
    return axiosApiInstance.post(`${serverUrl1}/addquickenquiry`, tempobj)
}

