// websiteData
import axios from "axios";
import { url } from "./url.service";

const serverUrl = `${url}/websiteData`


export const getWebsiteData = async () => {
    return axios.get(`${serverUrl}/`)
}



export const getSeoBySlugApi = async (seourl) => {
    return axios.post(`${url}seo/getSeoForHomepage`,{url:seourl})
}
