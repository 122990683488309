import { url } from './url.service'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query';
import { axiosApiInstance } from '../App';
const serverUrl = `${url}/reelVideo`

export const addBlogVideoApi = async (formData) => {
    return axios.post(`${serverUrl}/`, formData)
}

export const getBlogVideoApi = async (query) => {
    return axios.get(`${serverUrl}/?${query}`)
}

export const editBlogVideoApi = async (id, formData) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, formData)
}

export const getBlogVideoBySlugApi = async (id, formData) => {
    return axios.get(`${serverUrl}/getById/${id}`, formData)
}

export const deleteBlogVideoApi = async (id) => {
    return axios.delete(`${serverUrl}/deleteById/${id}`)
}


export const getBlogVideoBySlug = async (slug) => {
    return axios.get(`${serverUrl}/getById/:${slug}`)
}

export const checkIfUserLiked = async (reelId,userId) => {
  return await axiosApiInstance.post(`${url}/reelLike/reels/${reelId}/liked`,{userId});
};

export const getLikeCount = async (obj) => {
  return await axiosApiInstance.post(`${url}/reelLike/getLikeCount`,obj);
};
export const postReelView = async (obj) => {
  return await axiosApiInstance.post(`${url}/reelLike/viewReels`,obj);
};
export const toggleLIked = async (obj) => {
  return await axiosApiInstance.post(`${url}/reelLike/like`,obj);
};

const getBlogVideo = (
  searchObj
) => {
  const query = new URLSearchParams({
    ...searchObj,
  }).toString();
  return axios.get(`${serverUrl}/?${query}`
  );
};

export const useBlogVideo = (
  searchObj = {},
  enabled = true
) => {
	  return useQuery({
    queryKey: ["reeelvideo", searchObj],
    queryFn: () => getBlogVideo(searchObj).then((res) => res.data),
    enabled: enabled,
  });
};
