import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  editBlogVideoApi,
  getBlogVideoBySlugApi,
  getForHomepage,
  addBlogVideoApi,
} from "../../services/ReelVideo.service";
import { getBrandApi } from "../../services/brand.service";
import { getAllCategories } from "../../services/Category.service";
import {
  createFlashSales,
  getFlashSalebyId,
  updateFlashSalebyId,
} from "../../services/FlashSales.service";
import {
  AddProduct,
  getAllProducts,
  getProductById,
  updateProductApi,
} from "../../services/Product.service";
import { generateImageUrl } from "../../services/url.service";
import { getUserById } from "../../services/User.service";
import { toastError } from "../../utils/toastutill";
import FileUpload from "../Utility/FileUpload";
import { errorToast, successToast } from "../Utility/Toast";

export default function AddReel() {
  const navigate = useNavigate();
  let userObj = useSelector((state) => state.auth.user);
  const [userObjData, setUserObjData] = useState({});
  let id = useSelector((state) => state.auth.user._id);
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [isEditingModeOn, setIsEditingModeOn] = useState(false);
  const [productArr, setProductArr] = useState([]);
  const [productId, setProductId] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  const [productSlug, setProductSlug] = useState("");
  const [video, setVideo] = useState("");

  const onSubmit = async () => {
    try {
      if (`${description}` === "" || !description) {
        errorToast("Please select a description");
        return 0;
      }

      if (`${video}` == "" || !video) {
        errorToast("Please Add Video");
        return 0;
      }

      let obj = {
        userId: userObj._id,
        description,
        video,
        thumbnail,
      };

      if (isEditingModeOn) {
        let { data: res } = await editBlogVideoApi(searchParams.get("id"), obj);
        if (res.message) {
          successToast(res.message);
          navigate("View/My-Reel");
        }
      } else {
        let { data: res } = await addBlogVideoApi(obj);
        console.log(res, "asd");
        if (res.message) {
          successToast(res.message);
          navigate("/View/My-Reel");
        }
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetSaleById = async () => {
    try {
      let { data: res } = await getBlogVideoBySlugApi(searchParams.get("id"));
      if (res.data) {
        setThumbnail(res.data.thumbnail);
        setVideo(res.data.video);
        setDescription(res.data.description);
        // setProductArr(res.data)
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetUser = async () => {
    try {
      let { data: res } = await getUserById(id);
      if (res.data) {
        console.log(res.data, "dataa");
        setUserObjData(res.data);

        // if(res.data.advertisementDays > 0){
        //     var new_date = moment(new Date(), "DD-MM-YYYY").add(res.data.advertisementDays, 'days');
        //     setEndDate( moment(new Date(new_date)).format('YYYY-MM-DD'))
        //   }
      }
    } catch (err) {
      errorToast(err);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  useEffect(() => {
    if (searchParams.get("id")) {
      setIsEditingModeOn(true);
      handleGetSaleById();
    }
  }, [searchParams.get("id")]);

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12 col-md-12">
          <div className="right frormcontainer">
            <h3 className="heading formheading ps-3">
              {isEditingModeOn ? "Edit" : "Add"} Reel
            </h3>
            <form className="form profile-section-container ">
              <div className="row">
                <label>
                  Thumbail Image <span className="text-danger">*</span>
                </label>
                <div className="my-3">
                  {thumbnail &&
                    (thumbnail.includes("base64") ? (
                      <>
                        <video src={thumbnail} height={100} width={100} />
                      </>
                    ) : (
                      <>
                        <video
                          src={generateImageUrl(thumbnail)}
                          height={100}
                          width={100}
                        />
                      </>
                    ))}
                </div>
                <FileUpload
                  acceptedType={"thumbnail/png, thumbnail/gif, thumbnail/jpeg"}
                  onFileChange={(value) => {
                    setThumbnail(value);
                  }}
                />
              </div>
              <br />
              <div className="row">
                <label>
                  Video <span className="text-danger">*</span>
                </label>
                <div className="my-3">
                  {/* {thumbnail} */}

                  {video &&
                    (video.includes("base64") ? (
                      <>
                        <video src={video} height={100} width={100} />
                      </>
                    ) : (
                      <>
                        <video
                          src={generateImageUrl(video)}
                          height={100}
                          width={100}
                        />
                      </>
                    ))}
                </div>
                <FileUpload
                  acceptedType={"video/mp4,video/x-m4v,video/*"}
                  onFileChange={(value) => {
                    setVideo(value);
                  }}
                />
              </div>
              <br />

              <div className="col-md-12">
                <label>
                  Promotion Message <span className="text-danger">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={description}
                  placeholder=" Enter Message here"
                  // disabled
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  type="button"
                  onClick={() => {
                    onSubmit();
                  }}
                  className="btn btn-custom btn-yellow mt-2 px-4 fs-5 me-5"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
