import { useContext, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { RiShareForwardFill } from "react-icons/ri";
import { FaEye, FaHeart, FaRegHeart, FaShare, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { MdInsertComment } from "react-icons/md";
import {
  IoMdPause,
  IoMdPlay,
  IoMdVolumeHigh,
  IoMdVolumeOff,
} from "react-icons/io";
import { toast } from "react-hot-toast";
import { generateImageUrl } from "../services/url.service";
import { SignModalContext } from "../context/SignModalContext";
import { useSelector } from "react-redux";
import { getLikeCount, toggleLIked } from "../services/ReelVideo.service";
import { toastError } from "../utils/toastutill";
import { GoScreenFull } from "react-icons/go";
import { Link } from "react-router-dom";

const VideoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 1rem;
  height: calc(100vh - 2rem);
  .video {
 width: 100%;
    max-width: 280px;
    aspect-ratio: 9 / 16;
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    video {
      width: 100%;
    height: 100%;
    }
    .video-actions {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      button {
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#bf9f65 ;
        transition: 0.15s;
        &:hover {
          background: rgb(var(--light-color) / 0.25);
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    .video-details {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 3rem 1rem 1rem;
      background: linear-gradient(
        0deg,
        rgba(var(--dark-color) / 0.8) 0%,
        rgba(var(--dark-color) / 0) 100%
      );
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.75rem;
      p {
        font-size: 0.9rem;
        color: #fff;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .creator-details {
        display: flex;
        align-items: center;
        gap: 1rem;
        img {
          width: 2.25rem;
          height: 2.25rem;
          border-radius: 50%;
          object-fit: cover;
        }
        button {
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          font-size: 0.8rem;
          background: rgb(var(--primary-color));
        }
      }
    }
    .buttons {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 2rem 0.25rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      & > div {
        & span {
          display: block;
          font-size: 0.75rem;
          color: rgb(var(--light-color));
          text-align: center;
        }
        &.like {
          & button.liked {
            color: rgb(var(--like-color));
          }
        }
        &.dislike {
          & button.disliked {
            color: rgb(var(--primary-color));
          }
        }
      }
      button {
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(var(--light-color));
        transition: 0.15s;
        &:hover {
          background: rgb(var(--light-color) / 0.25);
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
`;

const Video = ({
  video,
  mute,
  setMute,
  playingVideo,
  setPlayingVideo,
}) => {
  const videoRef = useRef(null);
  const [play, setPlay] = useState(video._id === playingVideo);
 const [likesCount, setLikesCount] = useState(0);
   const [isLiked, setIsLiked] = useState(0);
   const auth = useSelector((state) => state.auth.user);
   const isAuthorized = useSelector((state) => state.auth.isAuthorized);
   const { openSignModal } = useContext(SignModalContext);
  const [showPlayPauseIcon, setShowPlayPauseIcon] = useState(false);
   const handleShare = async (video) => {
     const shareData = {
       title: video.title,
       text: `${video.description}!`,
       url: "https://plywoodbazar.com/video-detail/" + video?._id,
     };
 
     try {
       if (navigator.share) {
         await navigator.share(shareData);
       } else {
         alert("Sharing is not supported in this browser.");
       }
     } catch (error) {
       console.error("Error sharing:", error);
     }
   };
   // Handle like functionality
   const handleLike = async () => {
     if (!isAuthorized) {
       openSignModal();
       return;
     }
     try {
       await toggleLIked({ videoId: video?._id, userId: auth?._id });
       setIsLiked(!isLiked);
       setLikesCount((prev) => (isLiked ? prev - 1 : prev + 1));
     } catch (error) {
       toastError(error);
     }
   };
 
   // Fetch initial like count and user’s like status
   const fetchData = async () => {
     const { data: res } = await getLikeCount({
       videoId: video?._id,
       userId: auth?._id,
     });
     setLikesCount(res?.data?.likeCount || 0);
     setIsLiked(res?.data?.liked || 0);
   };
 
  useEffect(() => {
    const currentVideoRef = videoRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            entry.target.id !== playingVideo &&
            (entry.target)?.paused
          ) {
            setPlay(true);
            setPlayingVideo(entry.target.id);
            return;
          }
          if (!entry.isIntersecting) {
            setPlay(false);
            return;
          }
        });
      },
      {
        threshold: 0.5,
      }
    );
    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }
    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
    };
  }, [playingVideo, setPlayingVideo]);
  useEffect(() => {
    fetchData();
    if (play) {
      videoRef.current?.play();
      return;
    }
    videoRef.current?.pause();
  }, [play]);
  return (
    <div className="video-container">

    <VideoStyled>

      <div className="video selected">
        <video
          ref={videoRef}
          src={generateImageUrl(video.video)}
        {...(video?.thumbnail && {
                   poster: generateImageUrl(video.thumbnail), // Add the poster attribute only if a thumbnail exists
                 })}
          id={video._id}
          autoFocus
          autoPlay={play}
          loop={video._id === playingVideo}
          onClick={(event) => {
            event.stopPropagation();
            setPlay(!play);
          }}
          muted={mute}
        />
        <div className="video-actions">
          <div className="play-pause">
            {play ? (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setPlay(false);
                }}
              >
                <IoMdPause />
              </button>
            ) : (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setPlay(true);
                }}
              >
                <IoMdPlay />
              </button>
            )}
          </div>
          <div className="volume">
            {mute ? (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setMute(false);
                }}
              >
                <IoMdVolumeOff />
              </button>
            ) : (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setMute(true);
                }}
              >
                <IoMdVolumeHigh />
              </button>
            )}
          </div>
        </div>
   <div className="overlay-actions">
            <h4>
              <Link to={`/Supplier/${video.userId}`} style={{ color: "#fff" }}>
                {video.title}
              </Link>
            </h4>
            <p>{video.description}</p>
          </div>
       <div className="action-buttons">
                 <button onClick={handleLike} className="like-button">
                   {isLiked ? (
                     <FaHeart color="red" size={20} />
                   ) : (
                     <FaRegHeart color="#fff" size={20} />
                   )}
                   <span>{likesCount}</span>
                 </button>
                 <button className="like-button">
                   <FaEye size={20} />
                   <span>{video.views ? video.views : 0}</span>
                 </button>
                 <button onClick={() => handleShare(video)} className="share-button">
                   <FaShare size={20} />
                 </button>
                 <Link to={"/reel-detail/" + video._id} className="share-button">
                   <GoScreenFull size={20} />
                 </Link>
               </div>
      </div>
    </VideoStyled>
    </div>
  );
};

export default Video;