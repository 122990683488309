import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../Utility/Toast';
import { getUserNotifications, getupadateRead, getUserById, checkForValidSubscriptionAndReturnBoolean, markedAsReadNotificatins ,deleteAllNotification} from '../../services/User.service'; // Import your API call
import { toastError, toastSuccess } from '../../utils/toastutill';
import { Modal } from 'react-bootstrap';
import { addvendorReview } from '../../services/ProductReview.service';
import StarRatings from 'react-star-ratings';

// ===========================
// Helper functions for formatting date to IST and calculating time difference
function timeDifference(date) {
    const now = new Date();
    const diffInMs = now - new Date(date);
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
  
    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      return `${diffInDays} days ago`;
    }
  }
 
// ==============================
export default function UserNotifications() {
    const userObj = useSelector(state => state.auth.user);
    const { _id } = userObj || {};
    const auth = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const [reviewModal, setReviewModal] = useState(false);
    const [rating, setRating] = useState(0);

    const [userName, setUserName] = useState("");
    const [supplierId, setsupplierId] = useState("");
    const [message, setMessage] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [productArr, setProductArr] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [userSubscriptionStatus, setUserSubscriptionStatus] = useState({ expired: true, blocked: false });
    const [currentUserHasActiveSubscription, setCurrentUserHasActiveSubscription] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmitReview = async (e) => {
        try {
          e.preventDefault();
    
          if (userName == "") {
            toastError("Name is mandatory");
    
            return;
          }
    
          if (!(supplierId)) {
            toastError("Something went wrong please close the app and open again ");
    
            return;
          }
    
          let obj = {
            addedby: userObj._id,
            rating,
            message,
            name: userName,
            userId: supplierId,
            // productId: productObj?._id,
          };
    
          let { data: res } = await addvendorReview(obj);
    
          if (res.message) {
            toastSuccess(res.message);
            setReviewModal(false);
    
          }
        } catch (err) {
          toastError(err);
        }
      };
    const handleGetUser = async () => {
        try {
            const { data: res } = await getUserById(_id);
            if (res.data) {
                setUserSubscriptionStatus({
                    expired: res.data.userSubscriptionExpired,
                    blocked: res.data.isBlocked
                });
                  setUserName(res.data?.companyObj?.name ? res.data?.companyObj?.name: res.data?.name)

            }
        } catch (err) {
            errorToast(`Failed to fetch user data: ${err.message}`);
        }
    };

const deleteAll =  async () => {
    var result = window.confirm("Are You Sure to Delete all Notifications?");
        if (result) {
             const { data: res } = await deleteAllNotification(_id);
            if (res.success) {
              navigate('/notifications')
        }
}
}

    const handleGetProducts = async (skipValue, limitValue) => {
        setLoading(true);
        try {
            const query = `?page=${skipValue}&perPage=${limitValue}&userId=${_id}`;
            const { data: res } = await getUserNotifications({ userId: _id });
            if (res.data) {
                setTotalElements(res.totalElements);
                setProductArr(res.data);
                console.log(res.data, "hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii");
                
            }
        } catch (err) {
            errorToast(`Failed to fetch notifications: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Function to mark a notification as read when clicked
    const handleNotificationClick = async (notificationId) => {
        try {
            await getupadateRead({ userId: _id, notificationId });
            // Update the local state to reflect the read status
            setProductArr(prevArr =>
                prevArr.map(notification =>
                    notification._id === notificationId ? { ...notification, isRead: true } : notification
                )
            );

            if(!currentUserHasActiveSubscription){
                errorToast("You do not have a valid subscription to perform this action")
                return  false
            }

            return true

        } catch (err) {
            errorToast(`Failed to mark notification as read: ${err.message}`);
                return  false

        }
    };

    const changeRating = (e) => {
        console.log(e);
        setRating(e);
      };
    const debouncedSave = useCallback(
        debounce((nextValue) => handleGetProducts(page, limit, nextValue), 1000),
        []
    );

    const handlePageChange = (page) => {
        if (Math.ceil(totalElements / limit) >= page) {
            setPage(page);
            handleGetProducts(page, limit);
        }
    };
    const markedReadNotificatins = async  () => {
        try {
            await markedAsReadNotificatins(`userId=${_id}`)
        } catch (error) {
            toastError(error)
        }
    };

    const HandleCheckValidSubscription = async () => {
        try {
            const { data: res } = await checkForValidSubscriptionAndReturnBoolean(auth?._id);
            if (res.data) {
                setCurrentUserHasActiveSubscription(res.data);
            }
        } catch (err) {
            // Handle error as needed
        }
    };

    useEffect(() => {
        handleGetUser();
        handleGetProducts(page, limit);
        HandleCheckValidSubscription();
        markedReadNotificatins()
    }, [page, limit, searchQuery]);

    const calculateDaysLeft = (endDate) => {
        const currentDate = new Date();
        const end = new Date(endDate);
        const differenceInTime = end.getTime() - currentDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays >= 0 ? differenceInDays : 0; // Return 0 if the date has already passed
    };

    return (
        <div className="container-fluid topup-subscription-container">
            <div className="row d-flex justify-content-center">
                <div className="col-10 mb-5">

                    <h4 className="yellow">Your Notifications</h4> 
                      <span className="text-center  viewlbtn">

                      <button onClick={() =>deleteAll()}>Clear </button>
                      </span>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <div className="rounded">
                            <div className="row d-flex justify-content-between">
                                {productArr.map((item, index) => {
                                    const daysLeft = item.type === 'flash_sale'
                                        ? calculateDaysLeft(item.payload?.flashSaleDetails?.endDate)
                                        : null;

                                    const messagesByType = {
                                      profile_view:
                                        !currentUserHasActiveSubscription ? (
                                          "Someone viewed your profile."
                                        ) : (
                                          <>
                                            {" "}
                                            👁️{" "}
                                            <b>
                                              {item.payload?.organizationName}
                                            </b>{" "}
                                            viewed your profile.
                                          </>
                                        ),
                                      product_view:
                                        !currentUserHasActiveSubscription
                                          ? `Someone viewed your product ${item.payload?.productName}`
                                          : `${
                                              item.payload?.organizationName ||
                                              "An organization"
                                            } viewed your product ${
                                              item.payload?.productName ||
                                              "Unnamed Product"
                                            }`,
                                      contact:
                                        !currentUserHasActiveSubscription ? (
                                          "Someone contacted you."
                                        ) : (
                                          <>
                                            <b>
                                              {item.payload?.organizationName}
                                            </b>{" "}
                                            contacted you.
                                          </>
                                        ),
                                      flash_sale:
                                        daysLeft !== null
                                          ? `Get ${
                                              item.payload?.flashSaleDetails
                                                ?.discountValue ||
                                              "Unnamed Product"
                                            }% OFF our top-selling ${
                                              item.payload?.productName
                                            } for the next ${daysLeft} days only!`
                                          : `💥 Stock is limited, so grab the offer and Save Big!`,
                                      new_arrival: (
                                        <>
                                          🌟{" "}
                                          <b className="text-success">
                                            New Arrivals
                                          </b>{" "}
                                          Are Here! 🌟 Check out the latest
                                          Product{" "}
                                          <b>
                                            {" "}
                                            {
                                              item.payload?.productObj?.name
                                            }{" "}
                                          </b>{" "}
                                          to our collection!
                                        </>
                                      ),
                                      product_approval_confirmation: `👋 Hi , Your ${item.payload?.productDetails?.name} Is Approved! 👋`,
                                      product_review: `🌟 ${item.payload?.addeByOrganizationName} 🌟Reviewed your product ${item.payload?.productName}`,
                                      vendor_review: `${item.content}`,
                                      buy_top: `${item.content}`,
                                      buy_subscription: `${item.content}`,
                                      register: `${item.content}`,
                                      reel_approve: `${item.content}`,
                                      give_review: `Hi, Please share your review on how your experience was while talking with  ${item.payload?.organizationName}`,
                                      dealershipOpportunity: (
                                        <>
                                          Dealership opportunity at{" "}
                                          {
                                            item.payload?.organizationObj
                                              ?.address
                                          }{" "}
                                          by {item.payload?.organizationName}
                                          {/*🌟 Exclusive Dealership Opportunity Available For {item.payload?.organizationObj?.brandNames}! 🌟*/}
                                          {/*<br />*/}
                                          {/*{item.payload?.organizationName} is thrilled to announce a new dealership/distributor opportunity in {item.payload?.organizationObj?.address}! Join our growing network and become a part of our success story. Interested? Fill the given form to*/}
                                          <a href="#" className="fw-bold">
                                            &nbsp;Apply Now
                                          </a>{" "}
                                        </>
                                      ),
                                      product_under_review: (
                                        <>
                                          👋 Hi , Your{" "}
                                          <b className="text-success">
                                            {
                                              item?.payload?.productDetails
                                                ?.name
                                            }
                                          </b>{" "}
                                          is Now Under Review! 👋 We’ll notify
                                          you once the verification is complete.
                                          Thanks for your patience!
                                        </>
                                      ),
                                    };

                                    const message = messagesByType[item.type] || item.type;

                                    return (
                                        <div key={index} className="col-md-12 col-12 mt-2">
                                            <div
                                                className="profile-section-container"
                                                style={{
                                                    background: item.isRead ?  "#FFF":"#fcf2e4" , // Change background color based on isRead
                                                    boxShadow: "2px 4px 5px 0px #00000040"
                                                }}
                                                onClick={async () => {

                                                    if(item.type === "product_under_review") {
                                                        navigate(`/MyProducts/`);
                                                    }
                                                       if(item.type === "buy_top") {
                                                        navigate(`/My-Profile`);
                                                    }  
                                                    if(item.type === "register") {
                                                        navigate(`/My-Profile`);
                                                    }
                                                    if(item.type === "buy_subscription") {
                                                        navigate(`/MySubscriptions`);
                                                    }
                                                    if(item.type === "buy_subscription") {
                                                        navigate(`/MySubscriptions`);
                                                    }
                                                    if (
                                                      item.type ===
                                                      "reel_approve"
                                                    ) {
                                                      navigate(
                                                        `/MyReelView/My-Reel`
                                                      );
                                                    }
                                                    if(item.type === "flash_sale") {
                                                        navigate(`/ShopDetail/${item.payload?.slug}`);
                                                        return 0
                                                    }
                                                    let subscr =  await   handleNotificationClick(item._id);

                                                    console.log(subscr,"ssss"); // Log the entire item to see its structure
                                                    if(subscr){
                                                         if (item.type === "profile_view") {
                                                        navigate(`/Supplier/${item.payload.accessedBy}`);
                                                    }
                                                    else if(item.type === "give_review") {

                                                            setsupplierId(item.payload?.accessedBy)
                                                            setReviewModal(true)
                                                        } else if(item.type === "product_view") {
                                                            navigate(`/Supplier/${item.payload?.accessedBy}`);
                                                        } else if(item.type === "product_review") {
                                                            navigate(`/Supplier/${item.payload?.addedbyUserId}`);
                                                        }
                                                        else if(item.type === "vendor_review") {
                                                            navigate(`/Supplier/${item.payload?.addedbyUserId}`);
                                                        }
                                                        else if(item.type === "new_arrival") {
                                                            navigate(`/ShopDetail/${item.payload?.productObj?.slug}`);
                                                        }
                                                         else if(item.type === "contact") {
                                                            navigate(`/Supplier/${item.payload?.accessedBy}`);
                                                        }
                                                        else if(item.type === "dealershipOpportunity") {
                                                             navigate("/ApplyDealership", {
                                                                  state: {opportunity: item?.payload.opportunity },
                                                                });
                                                        }
                       
                                                         else {
                                                            console.log("Navigating to Supplier with ID:", item.payload?.addedbyUserObj?._id); // Log the ID
                                                            navigate(`/`);
                                                        } 
                                                    }
                                                     
                                                }}
                                                 // Add onClick handler here
                                            >
                                                <div className="row flex_direction_row">
                                                    <div className="col-6">
                                                        {item.payload?.bannerImage && <img src={item.payload.bannerImage} className='img-fluid' alt="Banner" />}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-2">
                                                        <div className='row'>
                                                            <div className='col-lg-6 col-md-6 col-sm-6 d-flex justify-content-start'>
                                                            <strong className='clr fs-5'>{new Date(item.createdAt).toDateString()}</strong>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-sm-6 d-flex justify-content-end'>
                                                            <p className="m-0 text-muted">
                                                              <small>{timeDifference(item.createdAt)}</small>
                                                            </p>
                                                                </div>
                                                        </div>
                                                      
                                                       
                                                        {/* <span>{item.lastAccessTime}</span> */}
                                                    </div>
                                                    <div className="text-dark">{message}</div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}


                                
      <Modal
        show={reviewModal}
        size="lg"
        centered
        onHide={() => setReviewModal(false)}
      >
        <Modal.Body className="review-modal custom-modal">
          <button
            type="button"
            class="btn-close right"
            aria-label="Close"
            onClick={() => setReviewModal(false)}
          ></button>
          <h3 className="heading brown my-2">Review Us</h3>
          <form className="form row">
            <div className="col-12 col-md-6">
              <label>Name</label>
              <input
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6">
              <label>Rating</label>
              <StarRatings
                rating={rating}
                starRatedColor="orange"
                starHoverColor="orange"
                changeRating={changeRating}
                numberOfStars={5}
                starDimension="28px"
                starSpacing="2px"
                name="rating"
              />
              {/* <input type="number" className="form-control" /> */}
            </div>
            <div className="col-12">
              <label>Comment</label>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                rows="5"
                className="form-control"
              ></textarea>
            </div>
            <div className="col-12">
              <button
                onClick={(e) => {
                  handleSubmitReview(e);
                }}
                className="btn btn-custom text-white yellow-bg py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
                            </div>
                            {/* Pagination Controls */}
                            <div className="pagination-controls">
                                {Array.from({ length: Math.ceil(totalElements / limit) }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        disabled={page === index + 1}
                                        className={`page-button ${page === index + 1 ? 'active' : ''}`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
