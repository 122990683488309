// local urlyar
// export const url = "http://192.168.221.84:8000";
export const url = "https://api.plywoodbazar.com/";
export const adminUrl = "https://admin.plywoodbazar.com";
//
// production url
// export const url = "https://api.plywoodbazar.com/test";
// export const url = "https://api.plywoodbazar.com";

export const generateImageUrl = (path) => {
  return `${url}/uploads/${path}`;
};
