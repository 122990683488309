
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getLikeCount,
  toggleLIked,
  getBlogVideoBySlugApi,
  postReelView,
  useBlogVideo,
} from "../services/ReelVideo.service";
import { generateImageUrl } from "../services/url.service";
import { FaShare, FaHeart, FaRegHeart, FaEye } from "react-icons/fa";
import { Link, useParams,useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SignModalContext } from "../context/SignModalContext";
import { toastError } from "../utils/toastutill";
import "./reels.css";
import { GoScreenFull } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import Video from "./ReelVideoNew";

const Reels = () => {
  const [reels, setReels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { data: videos } = useBlogVideo({ page, perPage: 2, status: true });

  const auth = useSelector((state) => state.auth.user);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const { openSignModal } = useContext(SignModalContext);
  const [totalPages, setTotal] = useState(0);
  const scrollContainerRef = useRef(null);
  const videoRefs = useRef({}); // Store references for each video
  const [hasMore, setHasMore] = useState(true);
  const [mute, setMute] = useState(true);
  const [playState, setPlayState] = useState(true); // Keep track of which video is playing

  const [blogDetails, setBlogDetails] = useState({});
  const params = useParams();

  const handleGetBlogDetail = async () => {
    try {
      let { data: res } = await getBlogVideoBySlugApi(params.id);
      if (res.data) {
        setBlogDetails(res.data);
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    handleGetBlogDetail();
  }, [params]);

  // Handle play/pause toggle for each video

  // Fetch more reels when scrolling
  const fetchReels = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    setPage((prevPage) => prevPage + 1);
  };

  const handlewView = async (videoId) => {
    try {
      let { data: res } = await postReelView({ reelId: videoId });
    } catch (error) {
      toastError(error);
    }
  };
  // Detect when the user reaches the bottom of the scrollable div
  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;
    // Check if the user has scrolled to the bottom
    const isAtBottom =
      container.scrollHeight === container.scrollTop + container.clientHeight;
    console.log(
      container.scrollHeight,
      container.scrollTop + container.clientHeight,
      isLoading
    );

    if (isAtBottom && !isLoading) {
      fetchReels();
    }
  };

  useEffect(() => {
    if (videos?.data) {
      if (videos.data && videos?.data?.length > 0) {
        Promise.resolve()
          .then(() => {
            console.log(videos.BlogVideoCount);
            // setReels(res?.data);
            setTotal(videos?.BlogVideoCount);

            if (page > 1 && videos?.data?.length > 0) {
              if (
                [...reels, ...videos?.data].length >= videos?.BlogVideoCount
              ) {
                setHasMore(false);
              }
              setReels([...reels, ...videos?.data]);
            } else {
              if (videos?.data.length >= videos?.BlogVideoCount) {
                setHasMore(false);
              }
              setReels(videos?.data);
            }
            setIsLoading(false);
          })
          .then(() => {
            setIsLoading(false);
          });
      } else {
        Promise.resolve()
          .then(() => {
            setReels([]);
            setTotal(0);
          })
          .then(() => {
            setIsLoading(false);
          });
      }
    }
  }, [videos]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      const container = scrollContainerRef.current;
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, hasMore]);

  // Handle play/pause toggle for each video
  const handlePlayPause = (videoId) => {
    const videoElement = videoRefs.current[videoId];

    // Pause all videos if any other video is playing
    Object.keys(videoRefs.current).forEach((id) => {
      if (id !== videoId && playState[id]) {
        videoRefs.current[id].pause(); // Pause other videos
        setPlayState((prev) => ({ ...prev, [id]: false })); // Update the play state for other videos
      }
    });

    // Toggle play/pause for the current video
    if (videoElement.paused) {
      videoElement.play(); // Play the current video
      handlewView(videoId);
      setPlayState((prev) => ({ ...prev, [videoId]: true }));
    } else {
      videoElement.pause(); // Pause the current video
      setPlayState((prev) => ({ ...prev, [videoId]: false }));
    }
  };

  return (
    <>
      <ReelVideo reel={blogDetails} />

      <div
        className="scroll-container"
        style={{ height: "75vh" }}
        onScroll={fetchReels}
      >
        {reels.map(
          (reel) =>
            reel &&
            reel._id != blogDetails?._id && (

              <Video
              key={reel._id}
              video={reel}
              mute={mute}
              setMute={setMute}
              playingVideo={playState}
              setPlayingVideo={setPlayState}
            />
            )
        )}
        {isLoading && <div className="loader">Loading...</div>}
      </div>
    </>
  );
};


const ReelVideo = ({ reel }) => {
  const [likesCount, setLikesCount] = useState(0);
  const [isLiked, setIsLiked] = useState(0);
  const videoRef = useRef(null); // Unique reference for each video
  const auth = useSelector((state) => state.auth.user);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const { openSignModal } = useContext(SignModalContext);
  const [playState, setPlayState] = useState(true); // Keep track of which video is playing
  const [mute, setMute] = useState(true);

  const handlewView = async (videoId) => {
    try {
      let { data: res } = await postReelView({ reelId: videoId });
    } catch (error) {
      toastError(error);
    }
  };

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    setPlayState(!playState);
    if (videoElement.paused) {
      videoElement.play();
      handlewView(reel?._id);
      // Play the current video
    } else {
      videoElement.pause(); // Pause the current video
    }
  };
  useEffect(() => {
    fetchData();
  }, [reel]);

  const handleShare = async (reel) => {
    const shareData = {
      title: reel.user,
      text: `Check out this reel by ${reel.title}!`,
      url: "https://plywoodbazar.com/reel-detail/" + reel?._id,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        alert("Shared successfully!");
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  // Handle like functionality
  const handleLike = async () => {
    if (!isAuthorized) {
      openSignModal();
      return;
    }
    try {
      await toggleLIked({ reelId: reel?._id, userId: auth?._id });
      setIsLiked(!isLiked);
      setLikesCount((prev) => (isLiked ? prev - 1 : prev + 1));
    } catch (error) {
      toastError(error);
    }
  };

  // Fetch initial like count and user’s like status
  const fetchData = async () => {
    const { data: res } = await getLikeCount({
      reelId: reel?._id,
      userId: auth?._id,
    });
    setLikesCount(res?.data?.likeCount || 0);
    setIsLiked(res?.data?.liked || 0);
  };

  return (
       <Video
                key={reel._id}
                video={reel}
                mute={mute}
                setMute={setMute}
                playingVideo={playState}
                setPlayingVideo={setPlayState}
              />
  );
};

export default Reels;
